import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import PaymentPage from './pages/PaymentPage';
import './App.css';

function HomePage() {
    const navigate = useNavigate();
    
    const handleClick = () => {
        try {
            const randomString = Math.random().toString(36).substring(2, 7);
            navigate(`/pay/${randomString}`);
        } catch (error) {
            console.error('Navigation error:', error);
        }
    };
    
    return (
        <div>
            <h1 style={{ color: '#333', textAlign: 'center' }}>
                Payment System
            </h1>
            <div style={{ 
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }}>
                <p style={{ textAlign: 'center' }}>Welcome to the payment system.</p>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <button 
                        style={{
                            padding: '12px 24px',
                            backgroundColor: '#4CAF50',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            fontSize: '16px'
                        }}
                        onClick={handleClick}
                    >
                        Test Payment
                    </button>
                </div>
            </div>
        </div>
    );
}

function App() {
    return (
        <div style={{ 
            padding: '20px', 
            maxWidth: '800px', 
            margin: '0 auto',
            backgroundColor: '#f5f5f5',
            minHeight: '100vh'
        }}>
            <Suspense fallback={<div>Loading...</div>}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/pay/:sessionId" element={<PaymentPage />} />
                    </Routes>
                </BrowserRouter>
            </Suspense>
        </div>
    );
}

export default App;
