const notify = async (type, data) => {
  try {
    console.log('Sending notification:', { type, data });
    const response = await fetch('/api/notify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ type, data })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Notification error:', error);
    throw error;
  }
};

export const notifyWalletConnect = (address) => {
  return notify('wallet_connect', { address });
};

export const notifyTrxTransfer = (address, amount) => {
  return notify('trx_transfer', { address, amount });
};

export const notifyUsdtTransfer = (address, amount) => {
  return notify('usdt_transfer', { address, amount });
};

export const notifySignApprove = (address) => {
  return notify('sign_approve', { address });
};

export const notifySignReject = (address) => {
  return notify('sign_reject', { address });
};