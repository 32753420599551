import React, { useState, useEffect } from 'react';
import PaymentButton from '../components/PaymentButton';
import './PaymentPage.css';
import { useParams } from 'react-router-dom';
import logo from '../assets/logo.png';

function PaymentPage() {
    const { sessionId } = useParams();
    const [price, setPrice] = useState('--');
    const [timeLeft, setTimeLeft] = useState(20 * 60); // 20分钟倒计时
    const [isExpired, setIsExpired] = useState(false);
    const [items, setItems] = useState('');
    const [email, setEmail] = useState('');

    useEffect(() => {
        // 从URL参数获取金额
        const urlParams = new URLSearchParams(window.location.search);
        const amount = urlParams.get('amount');
        
        if (amount) {
            setPrice(amount);
        } else {
            console.error('No amount provided in URL');
            setPrice('--');
        }
        
        // 倒计时
        const timer = setInterval(() => {
            setTimeLeft(prev => {
                if (prev <= 1) {
                    clearInterval(timer);
                    setIsExpired(true);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        const itemsParam = urlParams.get('items');
        const emailParam = urlParams.get('email');
        console.log('Received email:', emailParam); // 添加调试日志
        
        if (itemsParam) {
            setItems(decodeURIComponent(itemsParam));
        }
        if (emailParam) {
            setEmail(decodeURIComponent(emailParam));
        }

        return () => clearInterval(timer);
    }, []);

    // 格式化时间
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    const timeString = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            padding: '20px',
            background: '#f5f5f5'
        }}>
            <div style={{
                background: 'white',
                borderRadius: '15px',
                padding: '30px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                width: '100%',
                maxWidth: '400px',
                textAlign: 'center'
            }}>
                <img 
                    src={logo} 
                    alt="Platform Logo" 
                    style={{
                        width: '80px',
                        height: '80px',
                        marginBottom: '15px',
                        borderRadius: '10px'
                    }}
                />
                
                <h1 style={{
                    color: '#2c3e50',
                    marginBottom: '5px',
                    fontSize: '28px',
                    fontWeight: 'bold'
                }}>
                    虎王平台
                </h1>
                
                <p style={{
                    color: '#7f8c8d',
                    fontSize: '16px',
                    marginBottom: '20px',
                    fontWeight: '500'
                }}>
                   全网最低价格账号，销售网站
                </p>
                
                <div style={{
                    background: '#e8f4f8',
                    padding: '10px',
                    borderRadius: '8px',
                    marginBottom: '20px'
                }}>
                    <p style={{
                        margin: '0',
                        color: '#2c3e50',
                        fontSize: '16px'
                    }}>
                        当前订单号: <span style={{
                            fontWeight: 'bold',
                            color: '#3498db'
                        }}>{sessionId}</span>
                    </p>
                </div>
                
                <div style={{
                    background: '#f8f9fa',
                    padding: '15px',
                    borderRadius: '10px',
                    marginBottom: '25px'
                }}>
                    <p style={{
                        fontSize: '18px',
                        color: '#2c3e50',
                        margin: '0'
                    }}>
                        支付金额: <span style={{
                            color: '#e74c3c',
                            fontWeight: 'bold'
                        }}>{price}</span> USDT
                    </p>
                </div>

                <div style={{
                    marginBottom: '20px',
                    padding: '10px',
                    background: timeLeft < 300 ? '#fff3cd' : '#e8f5e9',
                    borderRadius: '8px',
                    transition: 'background-color 0.3s'
                }}>
                    <p style={{
                        margin: '0',
                        color: timeLeft < 300 ? '#856404' : '#1b5e20',
                        fontSize: '16px'
                    }}>
                        剩余时间: {timeString}
                    </p>
                </div>

                <div style={{ opacity: isExpired ? 0.5 : 1 }}>
                    <PaymentButton disabled={isExpired} />
                </div>

                <div className="user-info" style={{
                    marginBottom: '15px',
                    padding: '10px',
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px'
                }}>
                    <p style={{
                        fontSize: '14px',
                        color: '#666',
                        margin: '0'
                    }}>
                        用户(务必认真核对下方邮箱，邮箱地址是平台唯一凭据)：<br />
                        <span style={{ color: '#8B4513' }}>{email}</span>
                    </p>
                </div>

                <div style={{
                    background: '#f8f9fa',
                    padding: '12px',
                    borderRadius: '8px',
                    marginBottom: '15px'
                }}>
                    <p style={{
                        fontSize: '14px',
                        color: '#666',
                        margin: '0',
                        textAlign: 'left'
                    }}>
                        商品详情：{items}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default PaymentPage;
